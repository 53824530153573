import React, { useState } from "react";
import { Button, CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import l10n from "../../../../../../localization";
import Helpers from "../../../../../../helpers/helpers";
import InvoicePdf from "../../../InvoicePdf";
import InProcessInfo from "../../../InProcessInfo";
import MobileMeterInvoiceEntry from "../MobileMeterInvoiceEntry/MobileMeterInvoiceEntry";
import * as Styled from "./mobileInvoiceEntry.styles";

const MobileInvoiceEntry = (props) => {
  const [expanded, setExpanded] = useState(false);

  const { invoice } = props;
  if (!invoice) return;

  const renderInvoiceRow = (text) => {
    return (
      <Styled.InvoiceRow>
        <Col>
          <Styled.InvoiceRowStatus>{text}</Styled.InvoiceRowStatus>
          <Styled.InvoiceRowValue></Styled.InvoiceRowValue>
        </Col>
      </Styled.InvoiceRow>
    );
  };

  return (
    <Styled.Card>
      <CardHeader>
        <Row>
          <Col>
            <Styled.Month>
              {moment(invoice.invoiceIssueDate).format("MMMM YYYY")}
            </Styled.Month>
          </Col>
          <Col align={"right"}>
            <Styled.PriceTag status={invoice.isPayed}>
              <strong>
                {Helpers.formatPrice(
                  invoice.invoiceDebt > 0
                    ? invoice.invoiceDebt
                    : invoice.invoiceValue
                )}
              </strong>&nbsp;
              ({Helpers.formatPriceEur(
                  invoice.invoiceDebtEUR > 0
                    ? invoice.invoiceDebtEUR
                    : invoice.invoiceValueEUR
                )})
            </Styled.PriceTag>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Styled.InvoiceRow>
          <Col>
            <Styled.InvoiceRowKey>
              {l10n.invoices.meterLocation}
            </Styled.InvoiceRowKey>
            <Styled.InvoiceRowValue>
              {invoice.invoiceAddress}
            </Styled.InvoiceRowValue>
          </Col>
          <Col>
            <Styled.PDFWrapper>
              <InvoicePdf
                handleOpenInvoice={() => props.onOpenInvoice(invoice.invoiceId)}
                downloading={invoice.downloadingPdf}
              />
            </Styled.PDFWrapper>
          </Col>
        </Styled.InvoiceRow>
        <Styled.InvoiceRow>
          <Col>
            <Styled.InvoiceRowKey>
              {l10n.invoices.invoiceNumber}
            </Styled.InvoiceRowKey>
            <Styled.InvoiceRowValue>
              {invoice.invoiceNumber}
            </Styled.InvoiceRowValue>
          </Col>
        </Styled.InvoiceRow>
        <Styled.InvoiceRow>
          <Col>
            <Styled.InvoiceRowKey>{l10n.invoices.dueDate}</Styled.InvoiceRowKey>
            <Styled.InvoiceRowValue>
              {invoice.invoiceExpirationDate
                ? moment(invoice.invoiceExpirationDate).format("DD.MM.YYYY")
                : "-"}
            </Styled.InvoiceRowValue>
          </Col>
          <Col>
            <Styled.PDFWrapper>
              {props.gatewayWSPAYForm(invoice)}
              <InProcessInfo isPayed={invoice.isPayed} />
            </Styled.PDFWrapper>
          </Col>
        </Styled.InvoiceRow>

        {invoice.isReversed
          ? renderInvoiceRow(l10n.invoices.reversed)
          : invoice.invoiceValue < invoice.invoiceValueAdjusted
          ? renderInvoiceRow(l10n.invoices.increase)
          : invoice.invoiceValueAdjusted < invoice.invoiceValue
          ? renderInvoiceRow(l10n.invoices.decrease)
          : null}

        <Styled.ActionHolder>
          <Button color="link" onClick={() => setExpanded(!expanded)}>
            <Styled.MoreInfoWrapper>
              <p>{l10n.invoices.moreInfo}</p>
              <i className={`far fa-chevron-${expanded ? "up" : "down"}`}></i>
            </Styled.MoreInfoWrapper>
          </Button>
        </Styled.ActionHolder>
        {expanded &&
          invoice.meters.map((meter) => (
            <MobileMeterInvoiceEntry key={meter.meterCode} meter={meter} />
          ))}
      </CardBody>
    </Styled.Card>
  );
};

export default MobileInvoiceEntry;
