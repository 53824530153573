import styled from "styled-components";

export const ContentWrap = styled.div`
  background: linear-gradient(220.04deg, #192f7c 23.85%, #0166ab 100%);
  padding: 145px 80px 40px;
  height: 100%;

  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  h2 {
    margin: 0 0 100px;
  }
  .form-group {
    margin-bottom: 25px;
  }

  @media (min-width: 1200px) {
    min-height: 600px;
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    padding: 80px 60px;
    min-height: 500px;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    min-height: 400px;
  }

  @media (max-width: 991px) {
    padding: 40px;

    h2 {
      margin: 0 0 40px;
    }
  }

  @media (max-width: 575px) {
    padding: 20px;

    h2 {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 20px;
    }

    .form-group {
      margin-bottom: 15px;
    }
  }
`;
