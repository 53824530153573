import React, { useState } from "react";
import Helpers from "../../../../../../helpers/helpers";
import moment from "moment";
import Styled from "./InvoiceEntry.styles";
import l10n from "../../../../../../localization";
import { IconBarCode, IconPdf } from "../../../../../../components/Icons/Icons";
import Loader from "../../../../../Loader/Loader";
import InProcessInfo from "../../../InProcessInfo/InProcessInfo";

export default ({ invoice, show2dCode, gatewayWSPAYForm, onOpenInvoice }) => {
  const [metersVisible, setMetersVisible] = useState(false);

  return (
    <React.Fragment>
      <Styled.InvoiceWrapper
        key={invoice.invoiceId}
        cursorVisible={invoice.meters && invoice.meters.length > 0}
        onClick={() => {
          if (invoice.meters && invoice.meters.length > 0)
            setMetersVisible(!metersVisible);
        }}
      >
        <td>{invoice.invoiceAddress}</td>
        <td className="weight-medium">{invoice.invoiceNumber}</td>
        <td>{moment(invoice.invoiceIssueDate).format("DD.MM.YYYY")}</td>
        <td>
          {invoice.invoiceExpirationDate
            ? moment(invoice.invoiceExpirationDate).format("DD.MM.YYYY")
            : "-"}
        </td>
        <td>
          <strong>{Helpers.formatPrice(invoice.invoiceValue)}</strong> ({Helpers.formatPriceEur(invoice.invoiceValueEUR)})
        </td>
        <Styled.PriceWrapper>
          <strong>{Helpers.formatPrice(invoice.invoiceBalance)}</strong> ({Helpers.formatPriceEur(invoice.invoiceBalanceEUR)})
        </Styled.PriceWrapper>
        <Styled.PriceWrapper negative={true}>
          <strong>{Helpers.formatPrice(invoice.invoiceDebt)}</strong> ({Helpers.formatPriceEur(invoice.invoiceDebtEUR)})
        </Styled.PriceWrapper>
        <td>
          {invoice.downloadingPdf ? (
            <div style={{ paddingLeft: "20px", width: "100%" }}>
              <Loader inline={true} size={18} />
            </div>
          ) : (
            <Styled.HoverElement
              onClick={(e) => {
                e.stopPropagation();
                onOpenInvoice(invoice.invoiceId);
              }}
            >
              <IconPdf></IconPdf>
            </Styled.HoverElement>
          )}
        </td>
        <td>
          {invoice.isReversed ? (
            <b>{l10n.invoices.reversed}</b>
          ) : invoice.invoiceValue < invoice.invoiceValueAdjusted ? (
            <b>{l10n.invoices.increase}</b>
          ) : invoice.invoiceValueAdjusted < invoice.invoiceValue ? (
            <b>{l10n.invoices.decrease}</b>
          ) : (
            invoice.isPayed &&
            invoice.isPayed === "Open" && (
              <Styled.HoverElement
                onClick={(e) => {
                  e.stopPropagation();
                  show2dCode(invoice.invoiceId);
                }}
              >
                <IconBarCode />
              </Styled.HoverElement>
            )
          )}
        </td>
        <td>
          {gatewayWSPAYForm(invoice)}
          <InProcessInfo isPayed={invoice.isPayed} />
        </td>
      </Styled.InvoiceWrapper>

      <Styled.MetersRow visible={metersVisible}>
        <td colSpan="10">
          <table>
            <thead>
              <tr>
                <td>{l10n.invoices.measurementPoint}</td>
                <td>{l10n.invoices.meterCode}</td>
                <td>{l10n.invoices.readingDate}</td>
                <td>{l10n.invoices.reading}</td>
                <td>{l10n.invoices.sum}</td>
              </tr>
            </thead>
            <tbody>
              {(invoice.meters || []).map((meter, index) => (
                <tr key={`${meter.meterSiteCode}_${index}_${meter.meterCode}`}>
                  <td>{meter.address}</td>
                  <td>{meter.meterCode}</td>
                  <td>{moment(meter.readingDate).format("DD.MM.YYYY")}</td>
                  <td>{Helpers.formatNumber(meter.reading)}</td>
                  <td>{Helpers.formatNumber(meter.consumption)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </Styled.MetersRow>
    </React.Fragment>
  );
};
