import React from "react";
import * as Styled from "./SideNavigation.styles";
import { NavLink } from "react-router-dom";
import l10n from "../../../../../localization";
import moment from "moment/moment";
import renderIcon from "../../../../../components/Icons/Icons";

export default function SideNavigation(props) {
  const navigation = [
    {
      link: "/",
      icon: "IconDashboard",
      name: l10n.sideNavigation.dashboard
    },
    {
      link: "/invoices",
      icon: "IconTable",
      name: l10n.sideNavigation.invoices
    },
    {
      link: "/reports",
      icon: "IconChart",
      name: l10n.sideNavigation.reports
    },
    {
      link: "/send",
      icon: "IconBarometer",
      name: l10n.sideNavigation.send
    },
    {
      link: "/einvoice",
      icon: "IconEmailSidebar",
      name: l10n.sideNavigation.einvoice
    },
    {
      link: "/requests",
      icon: "IconRequests",
      name: l10n.sideNavigation.requests
    },
    {
      link: "/help",
      icon: "IconHelpCircle",
      name: l10n.sideNavigation.help
    },
    {
      link: "/profile",
      icon: "IconUser",
      name: l10n.sideNavigation.profile,
      showDivider: true,
      mobileOnly: true
    },
    {
      link: "/profile/change-password",
      icon: "IconPassword",
      name: l10n.sideNavigation.changePassword,
      mobileOnly: true
    },
    {
      link: "/logout",
      icon: "IconLogout",
      name: l10n.sideNavigation.logout,
      mobileOnly: true
    }
  ];

  return (
    <Styled.Nav>
      <ul>
        {navigation.map((navigationItem, index) => (
          <li
            key={index}
            className={navigationItem.mobileOnly ? "mobileOnly" : ""}
          >
            <NavLink
              to={navigationItem.link}
              exact
              onClick={props.onNavigationChange}
              className={navigationItem.showDivider ? "divider" : ""}
            >
              <Styled.LinkItem>
                <Styled.IconWrapper>
                  {renderIcon(navigationItem.icon)}
                </Styled.IconWrapper>
                <span>{navigationItem.name}</span>
              </Styled.LinkItem>
            </NavLink>
          </li>
        ))}
      </ul>
      <Styled.Author>
        &copy; {moment().format("YYYY")} VODOVOD PULA
        <span>v{process.env.REACT_APP_VERSION}</span>
      </Styled.Author>
    </Styled.Nav>
  );
}
