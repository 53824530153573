export const Types = {
  ONLINE_INVOICE: "einvoice/online_invoice",
  ONLINE_INVOICE_SUCCESS: "einvoice/online_invoice_success",
  ONLINE_INVOICE_ERROR: "einvoice/online_onvoice_error",
  ONLINE_NOTIFICATION: "einoivce/online_notification",
  ONLINE_NOTIFICATION_SUCCESS: "einvoice/online_notification_success",
  ONLINE_NOTIFICATION_ERROR: "einoivce/online_notification_error"
};

export const onlineInvoice = () => ({
  type: Types.ONLINE_INVOICE
});

export const onlineInvoiceSuccess = data => ({
  type: Types.ONLINE_INVOICE_SUCCESS,
  payload: data
});

export const onlineInvoiceError = error => ({
  type: Types.ONLINE_INVOICE_ERROR,
  payload: error
});

export const onlineNotification = () => ({
  type: Types.ONLINE_NOTIFICATION
});

export const onlineNotificationSuccess = data => ({
  type: Types.ONLINE_NOTIFICATION_SUCCESS,
  payload: data
});

export const onlineNotificationError = error => ({
  type: Types.ONLINE_NOTIFICATION_ERROR,
  payload: error
});
