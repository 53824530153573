import React from "react";
import * as Styled from "./Logo.styles";
import LogoImage from "../../../assets/logo-vodovod-pula.png";

export default function Logo() {
  return (
    <Styled.Logo background-color={"#ffffff"}>
      <img src={LogoImage} alt="VODOVOD PULA" />
      <Styled.Title>VODOVOD PULA</Styled.Title>
    </Styled.Logo>
  );
}
