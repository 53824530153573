import { call, fork, takeLatest, put, select } from "redux-saga/effects";
import { getUserInfo } from "../actions/user/user.actions";
import * as actions from "../actions/eInvoice.actions";
import * as api from "../api/eInvoice.api";

function* setOnlineInvoice() {
  try {
    const state = yield select();
    const response = yield call(
      api.onlineInvoice,
      state.user.userData.data.userId
    );

    yield put(actions.onlineInvoiceSuccess(response));
    yield put(getUserInfo(state.user.userData.data.userId));
  } catch (e) {
    yield put(actions.onlineInvoiceError(e));
  }
}

function* setOnlineNotification() {
  try {
    const state = yield select();
    const response = yield call(
      api.onlineNotification,
      state.user.userData.data.userId
    );

    yield put(actions.onlineNotificationSuccess(response));
    yield put(getUserInfo(state.user.userData.data.userId));
  } catch (e) {
    yield put(actions.onlineNotificationError(e));
  }
}

function* watchOnlineInvoice() {
  yield takeLatest(actions.Types.ONLINE_INVOICE, setOnlineInvoice);
}

function* watchOnlineNotification() {
  yield takeLatest(actions.Types.ONLINE_NOTIFICATION, setOnlineNotification);
}

export default [fork(watchOnlineInvoice), fork(watchOnlineNotification)];
