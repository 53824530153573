import React, { useState, useEffect } from "react";
import { Alert, Button, Form, Input } from "reactstrap";
import { connect } from "react-redux";
import {
  getMeters,
  newMeterReading,
  newMeterReadingClear,
  newMeterReadingChildren,
  newMeterReadingChildrenClear,
  meterErrorClear,
} from "../../redux/actions/meter.actions";
import moment from "moment";
import Loader from "../../components/Loader";
import OptionBox from "../../components/OptionBox";
import validation from "./Send.validation";
import l10n from "../../localization";
import * as Styled from "./Send.styles";
import * as Joi from "joi-browser";
import DatePicker from "react-datepicker";
import theme from "../../theme/default";

const Send = (props) => {
  const initialMeterState = {
    readingDate: null,
    value: null,
  };

  const [validate, setValidation] = useState({
    ...initialMeterState,
    readingDate: "",
  });
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [selectedUserMeter, setSelectedUserMeter] = useState(null);
  const [readingDate, setReadingDate] = useState(moment());
  const [readingValue, setReadingValue] = useState(null);
  const [readingValueAlertVisible, setReadingValueAlertVisible] = useState(
    false
  );
  const [childReadings, setChildReadings] = useState([]);
  const [readingsSum, setReadingsSum] = useState(0);

  let schema = Joi.object().keys({
    meterSiteCode: Joi.string().required(),
    meterCode: Joi.string().required(),
    readingDate: Joi.string().required(),
    value: Joi.number().required(),
  });

  useEffect(() => {
    if (!props.meters.loaded) props.getMeters();
    return () => {
      props.newMeterReadingClear();
    };
    //eslint-disable-next-line
  }, []);

  const filterDates = (date) => {
    const currentDate = moment(date);

    // Date restrictions from 15th to the end of the month
    let enabledFromDay = 15;

    if (process.env.REACT_APP_MEASUREMENT_ALWAYS_ENABLE === "true") {
      enabledFromDay = moment().startOf("month").date();
    }

    const startDate = moment().set("date", enabledFromDay).startOf("day");
    const endDate = moment(currentDate).endOf("month");

    return currentDate.isBetween(startDate, endDate, "hour", "[]");
  };

  const handleInputChanged = (e, type) => {
    let updatedReadings = childReadings;

    updatedReadings = childReadings.map((reading) => {
      if (reading.order === Number(e.target.name)) {
        return {
          ...reading,
          value: type === "value" ? e.target.value : reading.value,
          note: type === "note" ? e.target.value : reading.note,
        };
      }

      return reading;
    });

    let sum = 0;
    for (let reading of updatedReadings) {
      sum += Number(reading.value || 0);
    }

    setReadingsSum(sum);
    setChildReadings(updatedReadings);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (readingValue < selectedUserMeter.lastValue) {
      setReadingValueAlertVisible(true);
      return;
    }

    setReadingValueAlertVisible(false);

    const requestData = {
      meterSiteCode: selectedMeter.meterSiteCode,
      meterCode: selectedUserMeter.meterCode,
      readingDate: readingDate ? readingDate.format() : null,
      value: readingValue,
    };

    const validateResponse = validation(requestData, schema);

    if (!validateResponse) {
      props.newMeterReading(requestData);
      setValidation({ ...initialMeterState });
      return;
    }

    setValidation(validateResponse);
  };

  const handleSubmitNew = (e) => {
    e.preventDefault();

    const requestData = {
      meterSiteCode: selectedMeter.meterSiteCode,
      meterCode: selectedMeter.userMeters[0].meterCode,
      readingDate: readingDate ? readingDate.format() : null,
      value: readingValue,
      meterChildrenInputs: childReadings.map((reading) => {
        return {
          meterSiteCode: reading.meterSiteCode,
          meterCode: reading.meterCode,
          note: reading.note,
          value: reading.value ? Number(reading.value) : null,
        };
      }),
    };

    props.newMeterReadingChildren(requestData);
  };

  const handleSelectMeter = (item) => {
    const filteredMeters = props.meters.result.filter(
      (meter) => meter.meterSiteCode === item.slug
    );

    if (filteredMeters.length > 0) {
      const targetMeter = filteredMeters[0];
      setSelectedMeter(targetMeter);
      selectUserMeter(targetMeter);
      setReadingValueAlertVisible(false);

      if (targetMeter.isParentMeter) {
        setChildReadings(
          targetMeter.meterSiteChildren
            .sort((a, b) => a.order - b.order)
            .map((meter) => {
              return {
                order: meter.order,
                name: meter.name,
                meterSiteCode: meter.userMeterSite.meterSiteCode,
                meterCode: meter.userMeterSite.userMeters[0].meterCode,
                note:
                  meter.userMeterSite.userMeters[0]
                    .lastManualInsertionComment || "",
                value:
                  meter.userMeterSite.userMeters[0].lastManualInsertionValue ||
                  null,
              };
            })
        );
      }
      props.meterErrorClear();
    }
  };

  const handleSelectUserMeter = (item) => {
    const filteredUserMeters = selectedMeter.userMeters.filter(
      (userMeter) => userMeter.meterCode === item.slug
    );

    if (filteredUserMeters.length > 0) {
      setSelectedUserMeter(filteredUserMeters[0]);
      const insertionDate = filteredUserMeters[0].lastManualInsertionDate;
      if (insertionDate) {
        setReadingDate(moment(insertionDate));
      }

      setReadingValue(filteredUserMeters[0].lastManualInsertionValue);
      props.newMeterReadingClear();
      setReadingValueAlertVisible(false);
      props.meterErrorClear();
    }
  };

  const selectUserMeter = (meter) => {
    if (meter.userMeters && meter.userMeters.length > 0) {
      setSelectedUserMeter(meter.userMeters[0]);

      const insertionDate = meter.userMeters[0].lastManualInsertionDate;
      if (insertionDate) {
        setReadingDate(moment(insertionDate));
      }

      if (meter.userMeters[0] && meter.userMeters[0].lastManualInsertionValue) {
        setReadingValue(meter.userMeters[0].lastManualInsertionValue);
      }

      props.newMeterReadingClear();
    }
  };

  const getReadingType = () => {
    let label = "";
    let color = "";

    if (selectedUserMeter && selectedUserMeter.typeOfReading) {
      switch (selectedUserMeter.typeOfReading) {
        case "O":
          label = l10n.reports.readingType1;
          color = theme.colors[42];
          break;
        case "P":
          label = l10n.reports.readingType2;
          color = theme.colors[43];
          break;
        case "S":
          label = l10n.reports.readingType3;
          color = theme.colors[44];
          break;
        case "M":
          label = l10n.reports.readingType4;
          color = theme.colors[45];
          break;
        case "B":
          label = l10n.reports.readingType5;
          color = theme.colors[46];
          break;
        case "F":
          label = l10n.reports.readingType6;
          color = theme.colors[47];
          break;
        case "C":
          label = l10n.reports.readingType7;
          color = theme.colors[48];
          break;
        case "W":
          label = l10n.reports.readingType8;
          color = theme.colors[49];
          break;
        case "K":
          label = l10n.reports.readingType9;
          color = theme.colors[50];
          break;
        default:
          break;
      }
    }

    return color === "" || label === "" ? null : (
      <>
        {" ("}
        <Styled.LegendItem>
          <Styled.LegendIndicator color={color} />
          <Styled.LegendName>{label}</Styled.LegendName>
        </Styled.LegendItem>
        {")"}
      </>
    );
  };

  function renderSubmissionSection() {
    const currentDate = moment();

    // Date restrictions from 15th to the end of the month
    let enabledFromDay = 15;

    if (process.env.REACT_APP_MEASUREMENT_ALWAYS_ENABLE === "true") {
      enabledFromDay = moment().startOf("month").date();
    }

    const startDate = moment(currentDate)
      .set("date", enabledFromDay)
      .startOf("day");
    const endDate = moment(currentDate).endOf("month");

    if (selectedMeter && selectedMeter.isOnlyActivated === false) {
      return <Alert color="warning">{l10n.send.isOnlyActivated}</Alert>;
    }

    if (selectedMeter && !selectedMeter.manualReadingEnabled) {
      return <span>{l10n.send.manualReadingDisabled}</span>;
    }

    if (moment().isBetween(startDate, endDate, "hour", "[]")) {
      return (
        <>
          <Alert color="success" hidden={!props.meters.newPositionSaved}>
            {l10n.formatString(
              l10n.send.sendSuccess,
              selectedMeter.readingByNumOfPerson
                ? readingValue
                : readingValue - selectedUserMeter.lastValue || 0
            )}
          </Alert>
          {(!props.meters.newPositionSaved || selectedMeter.isParentMeter) && (
            <Button color="primary" type="submit">
              {l10n.send.sendButton}
            </Button>
          )}
        </>
      );
    } else {
      return (
        <Alert color="warning">
          {l10n.formatString(
            l10n.send.sendDateRestriction,
            enabledFromDay,
            endDate.format("DD")
          )}
        </Alert>
      );
    }
  }

  function renderMeterReadingView() {
    const currentDate = moment();

    // Date restrictions from 15th to the end of the month
    let enabledFromDay = 15;

    if (process.env.REACT_APP_MEASUREMENT_ALWAYS_ENABLE === "true") {
      enabledFromDay = moment().startOf("month").date();
    }

    const startDate = moment(currentDate)
      .set("date", enabledFromDay)
      .startOf("day");
    const endDate = moment(currentDate).endOf("day");

    if (selectedMeter && selectedMeter.isOnlyActivated === false) {
      return null;
    }

    if (currentDate.isBetween(startDate, endDate, "hour", "[]")) {
      return (
        <Styled.MeterReadingView>
          <Styled.MeterReadingRow>
            <Styled.MeterReadingKey>
              {l10n.send.dateOfPosition}
            </Styled.MeterReadingKey>
            <Styled.MeterReadingValue>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={moment().toDate()}
                disabled={!selectedMeter.isOnlyActivated}
                required
                onChange={(newDate) => setReadingDate(moment(newDate))}
                filterDate={filterDates}
                locale={l10n.general.locale}
              />
            </Styled.MeterReadingValue>
          </Styled.MeterReadingRow>
          <Styled.MeterReadingRow>
            <Styled.MeterReadingKey>{l10n.send.reading}</Styled.MeterReadingKey>
            <Styled.MeterReadingValue>
              <Input
                min={0}
                type="number"
                name="readingValue"
                id="readingValue"
                value={readingValue || ""}
                placeholder="unesite novo stanje vodomjera"
                disabled={!selectedMeter.isOnlyActivated}
                invalid={!!validate.value}
                required
                onChange={(e) => setReadingValue(e.target.value)}
              />
            </Styled.MeterReadingValue>
          </Styled.MeterReadingRow>
          <Styled.MeterReadingRow>
            <Styled.MeterReadingKey>
              {l10n.send.consumption}
            </Styled.MeterReadingKey>
            <Styled.MeterReadingValue>
              <Input
                type="number"
                value={
                  readingValue ? readingValue - selectedUserMeter.lastValue : ""
                }
                placeholder=""
                disabled={true}
              />
            </Styled.MeterReadingValue>
          </Styled.MeterReadingRow>
        </Styled.MeterReadingView>
      );
    }

    return null;
  }

  const renderChildMeters = () => {
    return childReadings.map((meter) => (
      <Styled.TableRow key={`${meter.order}_${meter.name}`}>
        <Styled.TableCol>{meter.order}</Styled.TableCol>
        <Styled.TableCol>{meter.name}</Styled.TableCol>
        <Styled.TableCol>
          <Input
            min={0}
            required
            type="number"
            name={meter.order}
            id={meter.order}
            placeholder="unesite novo stanje"
            value={meter.value || ""}
            onChange={(e) => handleInputChanged(e, "value")}
          />
        </Styled.TableCol>
        <Styled.TableCol>
          <Input
            type="text"
            name={meter.order}
            id={meter.order}
            placeholder=""
            value={meter.note}
            onChange={(e) => handleInputChanged(e, "note")}
          />
        </Styled.TableCol>
      </Styled.TableRow>
    ));
  };

  const renderParentForm = () => {
    return (
      <Form onSubmit={handleSubmitNew}>
        <Styled.Card>
          <Styled.ParentForm>
            <Styled.ParentFormHeader>
              <p>{l10n.send.dateOfPosition}</p>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={moment().ToDate()}
                disabled={!selectedMeter.isOnlyActivated}
                required
                onChange={(newDate) => setReadingDate(moment(newDate))}
                filterDate={filterDates}
                locale={l10n.general.locale}
              />
              <i className="fas fa-caret-down" />
            </Styled.ParentFormHeader>
            <Styled.ParentFormTable>
              <Styled.TableBody>
                <Styled.TableRow>
                  <Styled.TableCol>R.BR</Styled.TableCol>
                  <Styled.TableCol>Prezime i ime</Styled.TableCol>
                  <Styled.TableCol>
                    {selectedMeter.readingByNumOfPerson === true
                      ? "Broj osoba"
                      : "Potrošnja"}
                  </Styled.TableCol>
                  <Styled.TableCol>Napomena</Styled.TableCol>
                </Styled.TableRow>

                {renderChildMeters()}

                <Styled.TableRow>
                  <Styled.TableFooterCol>Ukupno</Styled.TableFooterCol>
                  <Styled.TableFooterInput>
                    <Input type="text" disabled={true} value={readingsSum} />
                  </Styled.TableFooterInput>
                </Styled.TableRow>
                <Styled.TableRow>
                  <Styled.TableFooterCol>
                    Stvarno stanje na vodomjeru (m3)
                  </Styled.TableFooterCol>
                  <Styled.TableFooterInput>
                    <Input
                      min={0}
                      type="number"
                      name="readingValueNew"
                      id="readingValueNew"
                      value={readingValue || ""}
                      placeholder="unesite novo stanje"
                      disabled={!selectedMeter.isOnlyActivated}
                      invalid={!!validate.value}
                      required
                      onChange={(e) => setReadingValue(e.target.value)}
                    />
                  </Styled.TableFooterInput>
                </Styled.TableRow>
              </Styled.TableBody>
            </Styled.ParentFormTable>
          </Styled.ParentForm>
        </Styled.Card>
        <Styled.SubmitButtonView>
          {renderSubmissionSection()}
        </Styled.SubmitButtonView>
      </Form>
    );
  };

  if (!props.meters.loaded) return <Loader />;

  return (
    <React.Fragment>
      <Styled.Header>
        <Styled.HeaderTitle>{l10n.send.sendDescription}</Styled.HeaderTitle>
        <Styled.HeaderText>
          {`${l10n.send.headerText} `}
          <Styled.HeaderLink
            href="/resources/DOJAVA_STANJA_VODOMJERA_-_UVJETI_12.03.21.pdf"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {l10n.send.headerLink}
          </Styled.HeaderLink>
        </Styled.HeaderText>
      </Styled.Header>
      <Styled.Filters>
        <Styled.InputWrap>
          <Styled.OptionText>{l10n.send.measurementPoint}</Styled.OptionText>
          <OptionBox
            options={[
              ...props.meters.result.map((meter) => {
                return {
                  slug: meter.meterSiteCode,
                  value: `${meter.siteAddress}`,
                };
              }),
            ]}
            loading={!props.meters.loaded}
            selected={selectedMeter ? selectedMeter.meterSiteCode : null}
            onSelect={handleSelectMeter}
            placeholder={l10n.send.choose}
          />
        </Styled.InputWrap>

        {selectedMeter && !selectedMeter.isParentMeter && (
          <Styled.InputWrap>
            <Styled.OptionText>{l10n.send.measurementMeter}</Styled.OptionText>
            <OptionBox
              options={
                selectedMeter && selectedMeter.userMeters
                  ? [
                      ...selectedMeter.userMeters.map((userMeter) => {
                        return {
                          slug: userMeter.meterCode,
                          value: `${userMeter.meterCode}`,
                        };
                      }),
                    ]
                  : []
              }
              loading={!props.meters.loaded}
              selected={selectedUserMeter ? selectedUserMeter.meterCode : null}
              onSelect={handleSelectUserMeter}
              placeholder={l10n.send.choose}
            />
          </Styled.InputWrap>
        )}
      </Styled.Filters>

      {selectedMeter &&
        (selectedMeter.isParentMeter ? (
          renderParentForm()
        ) : (
          <Form onSubmit={handleSubmit}>
            {selectedMeter && selectedMeter.isOnlyActivated !== false && (
              <Styled.Card>
                <Styled.MeterDataView>
                  <Styled.MeterDataRow>
                    <Styled.MeterDataKey>
                      {l10n.send.dateOfLastPosition}
                    </Styled.MeterDataKey>
                    <Styled.MeterDataValue>
                      {selectedUserMeter.lastReadingDate
                        ? moment(selectedUserMeter.lastReadingDate).format(
                            "DD.MM.YYYY"
                          )
                        : "-"}
                    </Styled.MeterDataValue>
                  </Styled.MeterDataRow>
                  <Styled.MeterDataRow>
                    <Styled.MeterDataKey>
                      {l10n.send.lastPosition}
                    </Styled.MeterDataKey>
                    <Styled.MeterDataValue>
                      {selectedUserMeter.lastValue}
                      {getReadingType()}
                    </Styled.MeterDataValue>
                  </Styled.MeterDataRow>
                </Styled.MeterDataView>

                {renderMeterReadingView()}
              </Styled.Card>
            )}

            <Styled.SubmitButtonView>
              {renderSubmissionSection()}
            </Styled.SubmitButtonView>
          </Form>
        ))}

      <Alert
        color="danger"
        hidden={!props.meters.error}
        style={{ marginTop: "20px" }}
      >
        {l10n.serverErrors[props.meters.error] || props.meters.error}
      </Alert>

      <Alert
        color="danger"
        hidden={!readingValueAlertVisible}
        style={{ marginTop: "20px" }}
      >
        <Styled.LastPositionError>
          {l10n.send.lastPositionError}{" "}
          <Styled.Link to="/help">{l10n.send.lastPositionError2}</Styled.Link>{" "}
          {l10n.send.lastPositionError3}
        </Styled.LastPositionError>
      </Alert>
    </React.Fragment>
  );
};

export default connect(({ meters }) => ({ meters }), {
  getMeters,
  newMeterReading,
  newMeterReadingChildren,
  newMeterReadingClear,
  newMeterReadingChildrenClear,
  meterErrorClear,
})(Send);
