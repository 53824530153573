import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Alert,
  FormText,
  Container,
} from "reactstrap";
import * as Styled from "./register.styles";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { IconHelpCircle, IconNumber, IconReveal } from "../../components/Icons/Icons";
import Modal from "../../components/Modal";

import {
  registerUser,
  registerUserReset,
  registerUserError,
} from "../../redux/actions/user/registerUser.actions";
import LogoColumn from "../../components/LoginComponents/LogoColumn";
import {
  LoginWrapper,
  FormWrapper,
  GlobalButton,
  GlobalLink,
} from "../../components/LoginComponents/LoginGlobal.styles";

import validation from "./Register.validation";
import l10n from "../../localization";
import hintScreenshot from "../../assets/hint_screenshot.png";

function Register(props) {
  const INITIAL_USER_STATE = {
    firstName: "",
    lastName: "",
    company: "",
    vat: "",
    userCode: "",
    email: "",
    password: "",
    passwordConfirm: "",
    policy: false,
  };

  const [user, setUser] = useState(INITIAL_USER_STATE);
  const [validate, setValidation] = useState(INITIAL_USER_STATE);
  const [hintModalOpened, setHintModalOpened] = useState(false);

  const handleRegister = (e) => {
    e.preventDefault();
    const validateResponse = validation(user);

    if (!validateResponse) {
      props.registerUser(user);
      setValidation(INITIAL_USER_STATE);
      return;
    }

    setValidation(validateResponse);
  };

  const handleChange = (e) => {
    const target = e.target;
    if (target.name === "policy") target.value = target.checked;

    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const toggleShowPassword = (e) => {
    const source = e.currentTarget;
    const input = source.parentElement.querySelector("input");

    if (input && input.type) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  };

  useEffect(() => {
    return () => {
      props.registerUserReset();
      props.registerUserError();
    };
    //eslint-disable-next-line
  }, []);

  if (props.user.registerUser.success) {
    return <Redirect to={`/activation-sent/${user.email}`} />;
  }

  function renderContactLink() {
    if (props.user.registerUser.error) {
      if (
        props.user.registerUser.error.filter(
          (item) => item === "user_data_does_not_match_with_registered_data"
        ).length > 0
      ) {
        return (
          <a href="https://vodovod-pula.hr/kontakt">
            {l10n.register.contactUs}
          </a>
        );
      }
    }

    return null;
  }

  return (
    <LoginWrapper>
      <Container>
        <Row noGutters={true}>
          <Col md="6" xs="12">
            <LogoColumn></LogoColumn>
          </Col>
          <Col md="6" xs="12">
            <FormWrapper>
              <Styled.ContentWrap>
                <Form onSubmit={handleRegister}>
                  <h2>{l10n.register.formTitle}</h2>

                  <Row>
                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label for="firstName" required>
                          <span className="d-none">
                            {l10n.register.firstName}*
                          </span>
                          <i className="far fa-user"></i>
                        </Label>
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder={l10n.register.firstName + "*"}
                          value={user.firstName}
                          invalid={!!validate.firstName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label for="lastName" required>
                          <span className="d-none">
                            {l10n.register.lastName}*
                          </span>
                          <i className="far fa-user"></i>
                        </Label>
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder={l10n.register.lastName + "*"}
                          value={user.lastName}
                          invalid={!!validate.lastName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label for="company">
                      <span className="d-none">{l10n.register.company}</span>
                      <i className="far fa-building"></i>
                    </Label>
                    <Input
                      type="text"
                      name="company"
                      id="company"
                      placeholder={l10n.register.company}
                      value={user.company}
                      invalid={!!validate.company}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="vat">
                      <span className="d-none">{l10n.register.vatNumber}*</span>
                      <IconNumber></IconNumber>
                    </Label>
                    <Input
                      type="text"
                      name="vat"
                      id="vat"
                      placeholder={l10n.register.vatNumber + "*"}
                      value={user.vat}
                      invalid={!!validate.vat}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="userCode">
                      <span className="d-none">{l10n.register.userCode}*</span>
                      <IconNumber></IconNumber>
                    </Label>
                    <Input
                      type="text"
                      name="userCode"
                      id="userCode"
                      placeholder={l10n.register.userCode + "*"}
                      value={user.userCode}
                      invalid={!!validate.userCode}
                      onChange={handleChange}
                    />
                    <Styled.HintButton onClick={() => setHintModalOpened(true)}>
                      <IconHelpCircle />
                    </Styled.HintButton>
                  </FormGroup>

                  <FormGroup>
                    <Label for="email">
                      <span className="d-none">
                        {l10n.register.emailAddress}*
                      </span>
                      <i className="far fa-envelope"></i>
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={l10n.register.emailAddress + "*"}
                      value={user.email}
                      invalid={!!validate.email}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">
                      <span className="d-none">{l10n.register.password}*</span>
                      <i className="fas fa-lock"></i>
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder={l10n.register.password + "*"}
                      value={user.password}
                      invalid={!!validate.password}
                      onChange={handleChange}
                    />
                    <FormText color={!!validate.password ? "danger" : "white"}>
                      {l10n.register.passwordDescription}
                    </FormText>

                    <Styled.PasswordButton
                      type="button"
                      onClick={toggleShowPassword}
                    >
                      <IconReveal></IconReveal>
                    </Styled.PasswordButton>
                  </FormGroup>

                  <FormGroup>
                    <Label for="passwordConfirm">
                      <span className="d-none">
                        {l10n.register.confirmPassword}*
                      </span>
                      <i className="fas fa-lock"></i>
                    </Label>
                    <Input
                      type="password"
                      name="passwordConfirm"
                      id="passwordConfirm"
                      placeholder={l10n.register.confirmPassword + "*"}
                      value={user.passwordConfirm}
                      invalid={!!validate.passwordConfirm}
                      onChange={handleChange}
                    />

                    <FormText
                      color={!!validate.passwordConfirm ? "danger" : "white"}
                    >
                      {l10n.register.confirmPasswordDescription}
                    </FormText>
                    <Styled.PasswordButton
                      type="button"
                      onClick={toggleShowPassword}
                    >
                      <IconReveal></IconReveal>
                    </Styled.PasswordButton>
                  </FormGroup>

                  <FormGroup>
                    <Styled.CheckboxWrapper>
                      <Input
                        type="checkbox"
                        name="policy"
                        id="policy"
                        value={user.policy}
                        onChange={handleChange}
                      />
                      <Label for="policy" className="static">
                        {l10n.register.agreeWith}{" "}
                        <Styled.Link
                          href="/resources/UVJETI_KORISTENJA_APLIKACIJE_MOJ_VODOVOD_-_PULA.pdf"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          {l10n.register.privacyPolicy}
                        </Styled.Link>
                      </Label>
                    </Styled.CheckboxWrapper>
                    {!!validate.policy && (
                      <Alert color="danger">{l10n.register.youMustAgree}</Alert>
                    )}
                  </FormGroup>

                  {props.user.registerUser.error && (
                    <FormGroup>
                      <Alert color="danger">
                        {l10n.general.somethingWentWrong}:
                        <ul>
                          {(props.user.registerUser.error || []).map(
                            (err, index) => (
                              <li key={index}>{l10n.serverErrors[err]}</li>
                            )
                          )}
                        </ul>
                        {renderContactLink()}
                      </Alert>
                    </FormGroup>
                  )}

                  <FormGroup className="text-lg-right mt-1">
                    <GlobalButton
                      color="primary"
                      disabled={props.user.registerUser.loading}
                    >
                      {l10n.register.register}
                    </GlobalButton>
                  </FormGroup>

                  <Styled.BottomGap></Styled.BottomGap>

                  <FormGroup className="text-lg-right">
                    {l10n.register.alreadyHaveAccount}{" "}
                    <GlobalLink to="/login">
                      {l10n.register.loginHere}
                    </GlobalLink>
                  </FormGroup>
                </Form>
              </Styled.ContentWrap>
            </FormWrapper>
          </Col>
        </Row>
      </Container>
      <Modal
        open={hintModalOpened}
        title={l10n.register.modalTitle}
        footer={true}
        onClose={() => setHintModalOpened(false)}
      >
        <p>{l10n.register.modalMessage}</p>
        <Styled.HintImage src={hintScreenshot} />
      </Modal>
    </LoginWrapper>
  );
}

export default connect(({ user }) => ({ user }), {
  registerUser,
  registerUserReset,
  registerUserError,
})(Register);
