import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Alert,
  Row,
  Container,
} from "reactstrap";
import * as Styled from "./Login.styles";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/user/user.actions";
import { activateUserReset } from "../../redux/actions/user/activateUser.actions";
import validation from "./Login.validation";
import l10n from "../../localization";
import ResendActivation from "./_components/resendActivation";
import {
  LoginWrapper,
  FormWrapper,
  GlobalLink,
  GlobalButton,
} from "../../components/LoginComponents/LoginGlobal.styles";
import LogoColumn from "../../components/LoginComponents/LogoColumn";
import Loader from "../../components/Loader";

function Login(props) {
  const INITIAL_USER_STATE = {
    email: "",
    password: "",
  };
  const [user, setUser] = useState(INITIAL_USER_STATE);
  const [validate, setValidation] = useState(INITIAL_USER_STATE);

  const submitLogin = (e) => {
    e.preventDefault();
    const validateResponse = validation(user);
    if (!validateResponse) {
      props.loginUser(user);
      setValidation(INITIAL_USER_STATE);
      return;
    }

    setValidation(validateResponse);
  };

  if (props.user.userData.success) {
    if (props.location.state && props.location.state.from.pathname) {
      return (
        <Redirect
          to={
            props.location.state.from.pathname +
            props.location.state.from.search
          }
        />
      );
    }

    return <Redirect to="/" />;
  }

  if (props.user.userData.resendActivationMail) {
    props.activateUserReset();
    return <Redirect to={`/activation-sent/${user.email}`} />;
  }

  if (props.user.userData.loading) return null;

  return (
    <LoginWrapper>
      <Container>
        <Row noGutters={true}>
          <Col md="6" xs="12">
            <LogoColumn></LogoColumn>
          </Col>
          <Col md="6" xs="12">
            <FormWrapper>
              <Styled.ContentWrap>
                <Form onSubmit={submitLogin}>
                  <h2>{l10n.login.formTitle}</h2>

                  <FormGroup>
                    <Label for="email">
                      <span className="d-none">{l10n.login.email}*</span>
                      <i className="far fa-envelope"></i>
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={l10n.login.email}
                      value={user.email}
                      invalid={!!validate.email}
                      onChange={(e) => {
                        setUser({ ...user, email: e.target.value });
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">
                      <span className="d-none">{l10n.login.password}*</span>
                      <i className="fas fa-lock"></i>
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder={l10n.login.password}
                      value={user.password}
                      invalid={!!validate.password}
                      onChange={(e) => {
                        setUser({ ...user, password: e.target.value });
                      }}
                    />
                  </FormGroup>

                  {props.user.resendUserActivationMail.success && (
                    <FormGroup>
                      <Alert color="success">{l10n.login.resentSuccess}</Alert>
                    </FormGroup>
                  )}

                  {!props.user.resendUserActivationMail.success &&
                    props.user.userData.error && (
                      <FormGroup>
                        <Alert
                          color="danger"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {l10n.general.somethingWentWrong}:
                          <ul>
                            {(props.user.userData.error || []).map(
                              (err, index) => (
                                <li key={index}>
                                  {l10n.serverErrors[err]}
                                  {err === "user_not_yet_activated" &&
                                    (props.user.resendUserActivationMail
                                      .loading ? (
                                      <Loader inline={true} />
                                    ) : (
                                      <ResendActivation email={user.email} />
                                    ))}
                                </li>
                              )
                            )}
                          </ul>
                        </Alert>
                      </FormGroup>
                    )}

                  <FormGroup>
                    <Row noGutters={true}>
                      <Col className="d-flex mb-lg-0 mb-md-4 mb-3">
                        <GlobalLink to="/password-recovery">
                          {l10n.login.forgottenPassword}
                        </GlobalLink>
                      </Col>
                      <Col lg="5" className="text-lg-right">
                        <GlobalButton color="primary">
                          {l10n.login.signIn}
                        </GlobalButton>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    {l10n.login.dontHaveAccount}{" "}
                    <GlobalLink to="/register">
                      {l10n.login.signUpHere}
                    </GlobalLink>
                  </FormGroup>
                </Form>
              </Styled.ContentWrap>
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </LoginWrapper>
  );
}

export default connect(({ user }) => ({ user }), {
  loginUser,
  activateUserReset,
})(Login);
