import React from "react";
import "./radio.css";

const Radio = (props) => {
  const { selected, onChange, value } = props;

  return (
    <div
      className="modern-radio-container"
      onClick={() => {
        onChange(value);
      }}
    >
      <div
        className={`radio-outer-circle ${value !== selected && "unselected"}`}
      >
        <div
          className={`radio-inner-circle ${
            value !== selected && "unselected-circle"
          }`}
        />
      </div>
    </div>
  );
};

export default Radio;
